import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import CreateContainerComponent from './components/createContainer.js'
import AuthComponent from "./components/auth.js"
import KeyManagerComponent from "./components/keyManager.js"
import DocComponent from "./components/doc.js"
import ContainerManagementComponent from './components/containers.js';
import DisplayDevEnv from './components/display.js';
import { Nav } from "react-bootstrap"
import axios from "axios"

const BASE_URL = window.location.origin.toString()

const buildNav = (setNav) => <>
  <Nav defaultActiveKey="/" as="ul" onSelect={(selectedKey) => setNav(selectedKey)}>
    <Nav.Item as="li">
      <Nav.Link eventKey="/">Instancier un environnement</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/access">Accéder aux environnements</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/management">Gestion des environnements</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/keyManagement">Gérer ma clef SSH</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/doc">Documentation</Nav.Link>
    </Nav.Item>
    <Nav.Item as="li">
      <Nav.Link eventKey="/logout">Déconnexion</Nav.Link>
    </Nav.Item>
  </Nav>
</>

function gotoEnv(uid, setNav, setGotoContainerUid) {
  setGotoContainerUid(uid)
  setNav("/access")
}

function BaseComponent() {
  const [sessionData, setSessionData] = useState(localStorage?.sessionData ? JSON.parse(localStorage.sessionData) : {})
  const [authToken, setAuthToken] = useState(localStorage.authToken)
  const [gotoContainerUid, setGotoContainerUid] = useState(null)
  const [nav, setNav] = useState("/")

  const gotoEnvDelegated = (uid) => { gotoEnv(uid, setNav, setGotoContainerUid) }

  useEffect(() => {
    if (nav === "/logout") {
      delete localStorage.sessionData
      delete localStorage.authToken
      setAuthToken(undefined)
      setSessionData(undefined)
      setNav("/")
    }
  }, [nav])

  useEffect(() => {
    if (authToken) {
      axios.post(`${BASE_URL}/user/checkauth`, { auth: authToken }).catch(err => {
        delete localStorage.sessionData
        delete localStorage.authToken
        window.location.reload()
      })
    }
  }, [authToken, sessionData])


  if (authToken) {
    if (nav === "/management") {
      const isAdmin = !!(sessionData?.isAdmin)
      return <>
        {buildNav(setNav)}
        <ContainerManagementComponent auth={authToken} isAdmin={isAdmin} gotoContainer={gotoEnvDelegated} />
      </>
    }
    if (nav === "/access") {
      return <>
        {buildNav(setNav)}
        <DisplayDevEnv auth={authToken} initDevUid={gotoContainerUid} />
      </>
    }
    if (nav === "/keyManagement") {
      return <>
        {buildNav(setNav)}
        <KeyManagerComponent auth={authToken} />
      </>
    }
    else if (nav === "/doc") {
      return <>
        {buildNav(setNav)}
        <DocComponent />
      </>
    }
    else {
      return <>
        {buildNav(setNav)}
        <CreateContainerComponent auth={authToken} sessionData={sessionData} gotoContainer={gotoEnvDelegated} />
      </>

    }
  }
  else return <AuthComponent setAuthToken={setAuthToken} setSessionData={setSessionData} />
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BaseComponent />
  </React.StrictMode>
)