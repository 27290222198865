import { Tab, Tabs, Spinner } from 'react-bootstrap';
import axios from "axios"
import React, { useState, useEffect } from 'react';

const BASE_URL = window.location.origin.toString()

const PROJECT_NAME_REGEXP = '.*\/(.*).git$'


function loadList(auth, setContainers) {
  return axios.post(`${BASE_URL}/user/containers/list`, { auth, adminMode: false })
    .then(res => {
      const result = res.data
      const filteredResult = result.filter(c => c.status === "ready")
      setContainers(filteredResult)
      return filteredResult
    })
}

function containerToOwnURL(container) {
  return `${BASE_URL}/instance/${container.uid}/${container.auth}/`
}

/**
 * @param {object} container
 * @param {string} container.project
 * @param {string} container.uid
 * @param {object} loadedTabs
 */
function containerToTabTitle(container, loadedTabs) {
  let res = container.uid
  const regexp = new RegExp(PROJECT_NAME_REGEXP)
  const match = container.project.match(regexp)
  if (match !== null) {
    const projectName = match[1]
    res += ` - ${projectName}`
  }
  if (isTabLoaded(loadedTabs, container.uid)) {
    res += " (chargé)"
  } else if (isTabOpened(loadedTabs, container.uid)) {
    res += " (chargement...)"
  } else {
    res += ""
  }
  return res
}

function isTabOpened(loadedTabs, uid) {
  return loadedTabs[uid] === "opened" || loadedTabs[uid] === "loaded"
}

function isTabLoaded(loadedTabs, uid) {
  return loadedTabs[uid] === "loaded"
}

function storeTabLoadState(loadedTabs, setLoadedTabs, uid, state) {
  const res = {}
  Object.assign(res, loadedTabs, { [uid]: state })
  setLoadedTabs(res)
}

function onTabSelect(loadedTabs, setLoadedTabs, setDefaultTab, uid) {
  setDefaultTab(uid)
  storeTabLoadState(loadedTabs, setLoadedTabs, uid, "opened")
}

function onContainerLoaded(containers, defaultTab, canDisplayTabs, setDefaultTab, setCanDisplayTabs) {
  if (!defaultTab && containers[0]) {
    setDefaultTab(containers[0].uid)
  }
  if (!canDisplayTabs && containers.length > 0) {
    setCanDisplayTabs(true)
  }
}

function onFrameLoaded(loadedTabs, setLoadedTabs, uid) {
  storeTabLoadState(loadedTabs, setLoadedTabs, uid, "loaded")
}

export default function DisplayDevEnv({ auth, initDevUid = null }) {
  const [containers, setContainers] = useState([])
  const [canDisplayTabs, setCanDisplayTabs] = useState(false)
  const [defaultTab, setDefaultTab] = useState(initDevUid)
  const [loadedTabs, setLoadedTabs] = useState({})



  useEffect(() => {
    loadList(auth, setContainers).then((containers) => onContainerLoaded(containers, defaultTab, canDisplayTabs, setDefaultTab, setCanDisplayTabs))
  }, [auth])

  useEffect(() => {
    const timer = setInterval(() => {
      loadList(auth, setContainers).then((containers) => onContainerLoaded(containers, defaultTab, canDisplayTabs, setDefaultTab, setCanDisplayTabs))
    }, 10000);
    return () => clearInterval(timer);
  })

  return <>
    <div className="flexVerticalContainer verticalGrower" id="devenvDisplay">
      {canDisplayTabs === true ? <Tabs id="selectEnvTabs" className="mb-3" defaultActiveKey={defaultTab} mountOnEnter unmountOnExit={false} fill>
        {containers.map(c => <Tab id={c.uid} className="" eventKey={c.uid} onSelect={() => onTabSelect(loadedTabs, setLoadedTabs, setDefaultTab, c.uid)} title={containerToTabTitle(c, loadedTabs)}>
          <div className="layeredContainer verticalGrower">
            {isTabLoaded(loadedTabs, c.uid) == false ?
              < div className="layeredBackground flexVerticalContainer flexCentered">
                <div class="spinnerwrapper"> <Spinner animation="border" /> </div>
              </div> : <></>}
            <div className="layeredForeground flexVerticalContainer verticalGrower" id="codeServerIframeWrapper">
              <iframe className="codeServerIframe verticalGrower" onload={() => onFrameLoaded(loadedTabs, setLoadedTabs, c.uid)} src={containerToOwnURL(c)} style={{ border: "none" }} title={c.uid}></iframe>
            </div >
          </div>
        </Tab>)}
      </Tabs> : <> Aucun environnement prêt </>}
    </div >
  </>
}