import React, { useState, useEffect } from 'react';
import * as BS from 'react-bootstrap'
import axios from "axios"

const BASE_URL = window.location.origin.toString()

const projectPlaceholder = "ssh://git@git.skales.fr:2222/skales/simtopy-dev-team/kickast.git"

const generatePassphrase = () => {
  const w1 = ["experimental", "modern", "attractive", "almighty", "mighty", "amazing", "awesome", "brillant", "literate", "genius", "handsome", "pie-flavored", "powerful", "zen", "super", "magnificent", "diegetic", "esoteric"]
  const w2 = ["michel", "bakunin", "kropotkin", "blanqui", "proudhon", "reclus", "ferre", "foucault", "chomski", "deleuze", "derrida", "debord", "wachowski", "guattari", "bataille", "lenin", "butler", "stirner", "wittgenstein", "lyotard", "baudrillard", "lukacs", "adorno", "gramsci", "bronte", "orwell", "tolstoi", "dostoievski", "marx", "watanabe", "zhaozhou", "joshu"]
  const w3 = ["activist", "comrade", "brother", "sister", "poet", "aviator", "pilot", "senator", "philosopher", "doctor", "performer", "artist", "insurgent", "rebel", "leftist", "writer", "sailor", "explorer", "discoverer", "narrator", "mystic", "communist", "anarchist", "player", "reader", "cowboy", "jazzman", "bluesman", "saxophonist"]

  const rand1 = crypto.getRandomValues(new Uint32Array(1))[0] % w1.length
  const rand2 = crypto.getRandomValues(new Uint32Array(1))[0] % w2.length
  const rand3 = crypto.getRandomValues(new Uint32Array(1))[0] % w3.length

  return `${w1[rand1]}-${w2[rand2]}-${w3[rand3]}`
}

const handleProject = (customProject, projectCloneSsh, projects, onChange) => {
  const hint = <>
    <BS.Form.Text className="text-muted">
      Les projets qui comportent un fichier .skales/autoconf.sh seront configurés automatiquement pour l'environnement de développement et prêts à démarrer.
      Cette fonctionnalité nécéssite une clef SSH.
    </BS.Form.Text>
  </>

  const select = projects === undefined ? <BS.Form.Select disabled name="projectCloneSsh" value={projectCloneSsh}>
    <option>Chargement en cours…</option>
  </BS.Form.Select> : <BS.Form.Select onChange={onChange} name="projectCloneSsh" value={projectCloneSsh}>
    <option>Sélectionner le projet</option>
    {projects !== undefined ? projects.map(p => <option value={p.ssh}> {p.label} </option>) : ""}
  </BS.Form.Select>

  const spinner = projects === undefined ? <div class="spinnerwrapper"> <BS.Spinner animation="border" size="sm" /> </div> : <></>

  if (customProject) return <>
    <BS.Form.Label>URI du projet à cloner (ssh)</BS.Form.Label>
    <BS.Form.Control type="text" name="projectCloneSsh" value={projectCloneSsh} onChange={onChange} placeholder={projectPlaceholder} />
    {hint}
  </>
  else return <>
    <div class="spinnerselect">
      {spinner}
      {select}
    </div>
    {hint}
  </>

}

export default function RootComponent({ auth, sessionData, gotoContainer }) {

  const [updateCounter, setUpdateCounter] = useState(0)
  const [barError, setBarError] = useState(false)
  const [barOn, setBarOn] = useState(false)
  const [awaitingCreation, setAwaitingCreation] = useState(false)
  const [barValue, setBarValue] = useState(0)
  const [containerStatus, setContainerStatus] = useState(null)
  const [containerUid, setContainerUid] = useState(null)
  const [containerAuth, setContainerAuth] = useState(null)
  //Form
  const [image, setImage] = useState("default")
  const [images, setImages] = useState([])
  const [sshPrivKey, setSshPrivKey] = useState("")
  const [password, setPassword] = useState(generatePassphrase())
  const [timeout, setTimeoutState] = useState(24)
  const [projectCloneSsh, setProjectCloneSsh] = useState("")
  const [projects, setProjects] = useState(undefined)
  const [customProject, setCustomProject] = useState(false)

  useEffect(() => {
    const checkProgressUpdate = () => {
      axios.post(`${BASE_URL}/devenv/ping`, {
        uid: containerUid
      }).then(res => {
        setBarValue(res.data.progress)
        setContainerStatus(res.data.status)
        setBarError(res.data.barError)
      }).then(res => {
        setUpdateCounter(updateCounter + 1)
      })

    }
    if (barValue < 100 && barOn) {
      setTimeout(checkProgressUpdate, 100)
    }
    if (barValue >= 100) {
      gotoContainer(containerUid)
    }
  }, [barOn, barValue, containerUid, updateCounter, containerAuth])


  useEffect(() => {
    axios.post(`${BASE_URL}/user/projects/list`, { auth })
      .then(res => {
        const result = res.data
        setProjects(result)
      })
  }, [auth])


  useEffect(() => {
    axios.post(`${BASE_URL}/images/list`, { auth })
      .then(res => {
        const result = res.data
        setImages(result)
      })
  }, [auth])


  const onChange = function (event) {
    const { name, value } = event.target
    switch (name) {
      case "image":
        setImage(value)
        break
      case "projectCloneSsh":
        setProjectCloneSsh(value)
        break
      case "sshPrivKey":
        setSshPrivKey(value)
        break
      case "password":
        setPassword(value)
        break
      case "timeout":
        setTimeoutState(value)
        break
      case "customProject":
        setCustomProject(value)
        break
      default:
        return
    }
  }

  const resetState = function (waitInterval) {
    clearInterval(waitInterval)
    setBarValue(0)
  }

  const submit = function (event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setBarValue(1)
    setContainerStatus("Creation requested")
    setAwaitingCreation(true)

    const formData = {
      image,
      sshPrivKey,
      projectCloneSsh,
      password,
      timeout: timeout * 3600,
      auth
    }

    axios.post(`${BASE_URL}/devenv/create`, formData)
      .then(res => {
        const result = res.data
        if (!!result.created === false) {
          alert("Impossible de créer le container. Contactez votre administrateur système.")
          resetState()
        }
        else {
          setContainerUid(result.created)
          setContainerAuth(result.auth)
          setBarOn(true)

        }
      })
      .catch(err => {
        alert(err.message)
        resetState()
      })


    event.preventDefault()
  }

  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <h1>Instancier un environnement de développement</h1>
        <BS.ProgressBar striped animated variant={barError ? "danger" : "success"} now={barValue} />
        {containerStatus ? <BS.Alert variant="light">
          {containerStatus}
        </BS.Alert> : <> </>
        }
        {!awaitingCreation ?
          <BS.Form onSubmit={submit} className="spaced">
            <div className="formUnit">
              <BS.Form.Label>Image du container de développement</BS.Form.Label>
              <BS.Form.Select type="text" name="image" value={image} onChange={onChange} >
                {images.map(i =>
                  <option value={i.id}>{i.name} </option>
                )}
              </BS.Form.Select>
              <BS.Form.Text className="text-muted">
                L'image par défaut comporte un adminer (accessible via /adminer), une base de donnée relationelle MariaDB (database) et deux instances de Redis (redis, queue) dans leurs propres containers.
              </BS.Form.Text>
            </div>
            <div className="formUnit">
              {handleProject(customProject, projectCloneSsh, projects, onChange)}
              <BS.Form.Check type="switch" id="customProject" label="Saisie manuelle" name="customProject" checked={customProject} onChange={() => setCustomProject(!customProject)} />
            </div>
            <BS.Row>
              <div className="formUnit">
                <BS.Form.Label>Mot de passe de session</BS.Form.Label>
                <BS.Form.Control required type="text" id="password" name="password" value={password} onChange={onChange} />
                <BS.Form.Text className="text-muted">
                  Ce mot de passe sera également utilisé pour les services annexes comme adminer. Ne rentrez jamais ici un mot de passe que vous utilisez pour d'autres services
                  et ne réutilisez pas ce mot de passe.
                </BS.Form.Text>
              </div>
            </BS.Row>

            <div className="formUnit">
              <BS.Form.Label>Durée de vie de la session (heures)</BS.Form.Label>
              <BS.Form.Control type="number" name="timeout" value={timeout} onChange={onChange} />
              <BS.Form.Text className="text-muted">7 Jours (168 heures) max.</BS.Form.Text>
            </div>
            <BS.Button disabled={awaitingCreation} type="submit">Créer l'environnement</BS.Button>
          </BS.Form >
          : ""}
      </BS.Col>
    </BS.Row>
  </BS.Container >
}
