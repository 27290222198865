import React, { useState } from 'react';
import * as BS from 'react-bootstrap'
import axios from "axios"

const BASE_URL = window.location.origin.toString()

export default function AuthComponent({ setAuthToken, setSessionData }) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const onChange = function (event) {
    const { name, value } = event.target
    switch (name) {
      case "username":
        setUsername(value)
        break
      case "password":
        setPassword(value)
        break
      case "error":
        setError(value)
        break
      default:
        return
    }
  }

  const submit = function (event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    const formData = {
      username,
      password
    }

    axios.post(`${BASE_URL}/auth`, formData)
      .then(res => {
        const result = res.data
        if (!!result.authToken === false) {
          setError("Login incorrect")
        }
        else {
          localStorage.sessionData = JSON.stringify(result.sessionData)
          localStorage.authToken = result.authToken
          setSessionData(result.sessionData)
          setAuthToken(result.authToken)
          setError("")
        }
      })
      .catch(err => {
        setError(err.message)
      })

    event.preventDefault()

  }


  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <h1>Authentification </h1>
        {error ? <BS.Alert variant="danger">{error}</BS.Alert> : ""}
        <BS.Form onSubmit={submit} className="spaced">
          <BS.Col>
            <BS.Row>
              <div className="formUnit">
                <BS.Form.Label>Nom d'utilisateur LDAP</BS.Form.Label>
                <BS.Form.Control required type="text" id="username" name="username" value={username} onChange={onChange} />
              </div>
            </BS.Row>
            <BS.Row>
              <div className="formUnit">
                <BS.Form.Label>Mot de passe LDAP</BS.Form.Label>
                <BS.Form.Control required type="password" id="password" name="password" value={password} onChange={onChange} />
              </div>
            </BS.Row>
          </BS.Col>
          <BS.Button type="submit">Valider</BS.Button>
        </BS.Form >
      </BS.Col>
    </BS.Row>
  </BS.Container>

}