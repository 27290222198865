import React, { useState, useEffect } from 'react';
import * as BS from 'react-bootstrap'
import axios from "axios"

const BASE_URL = window.location.origin.toString()

function loadList(setContainers, auth, adminMode) {
  axios.post(`${BASE_URL}/user/containers/list`, { auth, adminMode })
    .then(res => {
      const result = res.data
      setContainers(result)
    })
}

function displayAdminMode(isAdmin, adminMode, setAdminMode) {
  if (isAdmin) {
    return <BS.Form.Check type="switch" id="adminMode" label="Mode administrateur (Voir tous les conteneurs)" name="adminMode" checked={adminMode} onChange={() => setAdminMode(!adminMode)} />
  } else {
    return <></>
  }

}

function containerAction(actionType, container, setContainers, auth, adminMode, gotoContainer) {
  return () => {
    switch (actionType) {
      case "go": {
        gotoContainer(container.uid)
        break
      }
      case "open": {
        window.open(`${BASE_URL}/instance/${container.uid}/${container.auth}/`)
        break
      }
      case "link": {
        navigator.clipboard.writeText(`${BASE_URL}/instance/${container.uid}/${container.auth}/`);
        alert("Lien d'accès copié dans le presse papier.");
        break
      }
      case "extend": {
        axios.post(`${BASE_URL}/devenv/extend`, { uid: container.uid, auth: container.auth })
        alert("La durée de vie de l'environnement a été étendue de 24h. Le lien d'accès au container a été mis à jour. Veuillez récupérer le nouveau lien depuis cette page.")
        break
      }
      case "delete": {
        axios.post(`${BASE_URL}/devenv/destroy`, { uid: container.uid, auth: container.auth, cause: "manual action in frontend" })
        alert("Conteneur détruit.")
        break
      }
      default: {
        console.error("action unsupported")
      }
    }
    loadList(setContainers, auth, adminMode)
  }
}

function displayStatusDependantButton(cont, setContainers, auth, adminMode, gotoContainer) {
  const goMessage = "Aller"
  const openMessage = "Ouvrir (nouvel onglet)"
  const copyLinkMessage = "Copier lien d'accès"
  if (cont.status === "ready") {
    return <>
      <BS.Button variant="primary" size="sm" onClick={containerAction("go", cont, setContainers, auth, adminMode, gotoContainer)}>{goMessage}</BS.Button>
      <BS.Button variant="primary" size="sm" onClick={containerAction("open", cont, setContainers, auth, adminMode, gotoContainer)}>{openMessage}</BS.Button>
      <BS.Button variant="primary" size="sm" onClick={containerAction("link", cont, setContainers, auth, adminMode, gotoContainer)}>{copyLinkMessage}</BS.Button>
    </>
  } else {
    return <>
      <BS.Button variant="primary" size="sm" disabled>{goMessage}</BS.Button>
      <BS.Button variant="primary" size="sm" disabled>{openMessage}</BS.Button>
      <BS.Button variant="primary" size="sm" disabled>{copyLinkMessage}</BS.Button>
    </>
  }

}

function containerRow(cont, setContainers, auth, adminMode, gotoContainer) {
  const ttl = cont.ttl - (Date.now() - cont.createdAt)
  const ttlSeconds = Math.floor(ttl / 1000)
  const ttlMinutes = Math.floor(ttlSeconds / 60)
  const ttlHours = Math.floor(ttlMinutes / 60)
  const ttlDays = Math.floor(ttlHours / 24)
  const realHours = ttlHours % 24
  const realMinutes = ttlMinutes % 60
  const ttlDisplay = ttl < 0 ? "expiré !" : `${ttlDays}j ${realHours}h ${realMinutes}m`
  return <tr>
    <td>{cont.uid}</td>
    <td> {cont.owner} </td>
    <td> {cont.image}</td>
    <td> {cont.project}</td>
    <td> {cont.status}</td>
    <td> {ttlDisplay} </td>
    <td>
      {displayStatusDependantButton(cont, setContainers, auth, adminMode, gotoContainer)}
      <BS.Button variant="primary" size="sm" onClick={containerAction("extend", cont, setContainers, auth, adminMode, gotoContainer)}>Etendre durée de vie (24h)</BS.Button>
      <BS.Button variant="danger" size="sm" onClick={containerAction("delete", cont, setContainers, auth, adminMode, gotoContainer)}>Détruire</BS.Button>
    </td>
  </tr>
}

export default function ContainerManagementComponent({ auth, isAdmin, gotoContainer }) {
  const [containers, setContainers] = useState([])
  const [adminMode, setAdminMode] = useState(false)

  useEffect(() => {
    loadList(setContainers, auth, adminMode)
  }, [auth, adminMode])

  useEffect(() => {
    const timer = setInterval(() => {
      loadList(setContainers, auth, adminMode)
    }, 10000);
    return () => clearInterval(timer);
  })

  return <BS.Container>
    <BS.Row>
      <BS.Col>
        <h1>Gestion des environnements instanciés</h1>
        {displayAdminMode(isAdmin, adminMode, setAdminMode)}
        <BS.Table>
          <thead>
            <tr>
              <th>UID</th>
              <th>Propriétaire</th>
              <th>Image</th>
              <th>Projet</th>
              <th>Statut</th>
              <th>Durée de vie restante</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {containers.map(c => (containerRow(c, setContainers, auth, adminMode, gotoContainer)))}
          </tbody>
        </BS.Table>
      </BS.Col>
    </BS.Row>
  </BS.Container>

}




